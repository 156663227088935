import { all, fork } from 'redux-saga/effects'; // {call, put, takeEvery, takeLatest, take} was removed
// import { UserActions, updateUser, authSuccess, authReset, addPosts, addSquads, addMessages, removeMessages, SquadActions, addGames, PostsActions, appendPosts, addPostsGlobal, GlobalPostsActions, removePosts, LeaderboardsActions, deleteParticipants, addParticipants } from '../actions';
// import { getUser, registerTemp } from '../api/user';
// import { postPhone, postCode } from '../api/auth';

import Cookies from 'universal-cookie';
// import { getPosts } from '../api/posts';
// import { getGlobalPosts } from '../api/globalPosts';
// import { getSquads } from '../api/squads';
// import { getMessages } from '../api/messages';
// import { getOwnedGames } from '../api/ownedGames';
// import { getLeaderboards } from '../api/leaderboards';

// import countries from '../countries';

// function getCountryByName(name) {
// 	return countries[name];
// }

// const cookies = new Cookies();

function* watchProducts() {

}



const sagas = [
    watchProducts,
]

function* mySaga() {
    yield all(sagas.map(fork))
}

export default mySaga;