import React, { Component } from 'react'
import './App.css'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
// import Product from './components/Product';
// import Company from './components/Company';
// import Dashboard from './components/Dashboard';
// import Admin from './components/Admin';
// import { userAuth, fetchUser, authSuccess } from './actions'
import Cookies from 'universal-cookie'
import contexts from './contexts'
import { NoMatch } from './components/NoMatch'

const cookies = new Cookies()

class App extends Component {
  componentDidMount() {}

  render() {
    console.log(contexts)
    return (
      <div>
        <Router>
          <div>
            {/* <Header /> */}
            <Switch>
              <Route component={NoMatch} />
            </Switch>
          </div>
        </Router>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    // todos: getVisibleTodos(state.todos, state.visibilityFilter)
  }
}

export default connect(mapStateToProps)(App)
