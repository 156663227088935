import { LocaleActions } from '../actions'

const initialState = 'ru'

const locale = (state = initialState, action) => {
  switch (action.type) {
    case LocaleActions.CHANGE_LOCALE:
      return action.payload
    default:
      return state
  }
}

export default locale
