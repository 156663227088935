export default {
  ru: {
    HEADER_TITLE: 'СОНУС',
    HEADER1: 'Product',
    HEADER2: `For Dev's`,
    HEADER3: 'Platform',
    HEADER4: 'Our Work',
    HEADER5: 'Security',

    MENU_ADVANTAGES: 'Advantages',
    MENU_OVERVIEW: 'Overview',
    MENU_INTEGRATION: 'Integration',
    MENU_DASHBOARD: 'Dashboard',
    MENU_COMPETITORS: 'Competitors',
    MENU_PRICING: 'Pricing',
    MENU_CONTACTS: 'Contacts',

    CHANGE_LOCALE: 'English ver.',
    HEADER_CLIENTS: 'Current clients',

    ADVANTAGE_TITLE: 'Advantages',
    ADVANTAGE_1: 'Seemless integration',
    ADVANTAGE_2: 'Dashboard',
    ADVANTAGE_3: 'Cross-Platform sdk',
    ADVANTAGE_4: 'Service availability',

    ADVANTAGE_DESC_1: 'Native SDK for every supported platform',
    ADVANTAGE_DESC_2: 'Track the live heartbeat of your players communications',
    ADVANTAGE_DESC_3: 'Unity, Unreal, CryEngine, Lumberyard, iOS, Android, Windows, MacOS',
    ADVANTAGE_DESC_4: '99.995% SLA',

    INTEGRATION_TITLE: 'Integration',
    INTEGRATION_1: 'Dedicated Integration Team',
    INTEGRATION_2: 'Default UX/UI toolkit or fully customizable UX/UI SDK',
    INTEGRATION_3: 'Multi-SDK provider support: use your existing VoiceCom solution alongside Sonus SDK',
    INTEGRATION_4: '24/7 Support',
    INTEGRATION_5: 'Dedicated Account Manager',
    INTEGRATION_6: 'Unlimited Infrastucture Scalability',

    PRICING_TITLE: 'Pricing',
    PRICING_FREE: 'Free of charge',
    PRICING_FREE_DESC1: 'Less than 10k',
    PRICING_FREE_DESC2: 'AVERAGE MONTHLY DAU',
    PRICING_ENTERPRISE1: 'Enterprise 10k+',
    PRICING_ENTERPRISE2: 'AVERAGE MONTHLY DAU',
    PRICING_ENTERPRISE_DESC1: '$330 per 1k',
    PRICING_ENTERPRISE_DESC2: 'AVERAGE MONTHLY DAU',


    Main_header: 'SONUS — the next-gen voicecom chat for game developers',
    Main_Desc: `Introducing the advanced technology to support your game or project.`,
    Main_Button: 'TRY NOW',
    Platforms_Title: 'PLATFORM',
    Platforms_Desc: 'Engage users on their preferred devices using a single solution',
    Code_block_Title: 'INTEGRATE IN MINUTES',
    Code_block_Desc: 'A few lines of code that scale our user base to the moon.',
    LANDING_TITLE_8: 'OUR PERFOMANCE',
    LANDING_TITLE_9: 'Competitive matrix',
    LANDING_TITLE_10: 'Technology',
    LANDING_TITLE_11: 'WebRTC',
    LANDING_TITLE_12: 'SIP',
    LANDING_TITLE_13: 'Cross-Platform',
    features_1: [
      {
        name: 'Windows',
        sonus: 'Yes',
        vivox: 'Yes',
        twilio: 'Yes',
        agora: 'Yes',
      },
      {
        name: 'MacOS',
        sonus: 'Yes',
        vivox: 'Yes',
        twilio: 'Yes',
        agora: 'Yes',
      },
      {
        name: 'PS4',
        sonus: 'Yes',
        vivox: 'Yes',
        twilio: 'Yes',
        agora: 'Yes',
      },
      {
        name: 'XBOX',
        sonus: 'No',
        vivox: 'Yes',
        twilio: 'Yes',
        agora: 'Yes',
      },
      {
        name: 'Android',
        sonus: 'Yes',
        vivox: 'Yes',
        twilio: 'Yes',
        agora: 'Yes',
      },
      {
        name: 'iOS',
        sonus: 'Yes',
        vivox: 'Yes',
        twilio: 'Yes',
        agora: 'Yes',
      },
      {
        name: 'Web',
        sonus: 'Yes',
        vivox: 'Yes',
        twilio: 'Yes',
        agora: 'Yes',
      },
      {
        name: 'Nintendo Switch',
        sonus: 'Yes',
        vivox: 'Yes',
        twilio: 'Yes',
        agora: 'Yes',
      }
    ],
    features_2: [
      {
        selected: true,
        name: 'Remote Call Control',
        sonus: 'Yes',
        vivox: 'No',
        twilio: 'No',
        agora: 'No',
      },
      {
        name: 'Voice Detection',
        sonus: 'Yes',
        vivox: 'Yes',
        twilio: 'Yes',
        agora: 'Yes',
      },
      {
        selected: true,
        name: '"Hot" Devices Switch',
        sonus: 'Yes',
        vivox: 'No',
        twilio: 'No',
        agora: 'No',
      },
      {
        name: 'Personal Mute',
        sonus: 'Yes',
        vivox: 'Yes',
        twilio: 'Yes',
        agora: 'Yes',
      },
      {
        name: 'Push To Talk',
        sonus: 'Yes',
        vivox: 'Yes',
        twilio: 'Yes',
        agora: 'Yes',
      },
      {
        name: 'Custom Mic Level',
        sonus: 'Yes',
        vivox: 'Yes',
        twilio: 'Yes',
        agora: 'Yes',
      },
      {
        name: 'Reconnect',
        sonus: 'Yes',
        vivox: 'Yes',
        twilio: 'Yes',
        agora: 'Yes',
      },
      {
        selected: true,
        name: 'Fault Tolerance',
        sonus: 'Yes',
        vivox: 'No',
        twilio: 'No',
        agora: 'No',
      },
      {
        name: 'Horizontal Scaling',
        sonus: 'Yes',
        vivox: 'Yes',
        twilio: 'Yes',
        agora: 'Yes',
      },
      {
        selected: true,
        name: 'P2P Calls',
        sonus: 'Yes',
        vivox: 'No',
        twilio: 'No',
        agora: 'No',
      },
      {
        name: 'Text Messaging',
        sonus: 'Yes',
        vivox: 'Yes',
        twilio: 'Yes',
        agora: 'Yes',
      }
    ],
    // LANDING_TITLE_8:
    //   'Powerful dashboard tools enable you to tailor customer journeys that previously required programming expertise or expensive hardware.',
    // LANDING_TITLE_9: 'Extensive API documentation',
    // LANDING_TITLE_10: 'Libraries for popular languages',
    // LANDING_TITLE_11: 'Intuitive, easy-to-use tutorials',
    Overview_block_Features: 'FEATURES',
    Overview_block_Features_Desc: 'Experience voice services like never before',
    Overview_block_Features_First_Title: 'STABLE CONNECTION',
    Overview_block_Features_First_Desc:
      'Every call deserves the best route. With our one-to-one or groups calls SmartRouting engine, we always find the fastest stable connection for your users in the different locations.',
    Overview_block_Features_Second_Title: 'OPTIMAL CONFIGURATIONS',
    Overview_block_Features_Second_Desc: `Responsive real-time voice detection prevents appearing of background noise and other disturbances for the best voice experience for all users inside your project.`,
    Overview_block_Features_Third_Title: 'COMPLETE SET',
    Overview_block_Features_Third_Desc:
      'Mute the sound personally for every participant, switch a call between the devices on the go, create groups up to 20 people, control your PC call from mobile remotely and more.',
    LANDING_TITLE_20: 'QUALITY',
    LANDING_TITLE_21: 'The infrastructure that makes the difference',
    LANDING_TITLE_22: 'CALL LIKE A LOCAL FROM ANYWHERE',
    LANDING_TITLE_23:
      'Do business like a local company anywhere in the world. Get a reliable caller ID and use our local or toll-free Numbers to create localized experiences.',
    LANDING_TITLE_24: 'VOICE CAPABILITIES',
    LANDING_TITLE_25:
      'Create custom Voice applications with our Voice API. Add menus to your call flow with IVR or protect user’s privacy with masked numbers.',
    LANDING_TITLE_26: 'NO HIDDEN COST',
    LANDING_TITLE_27:
      'Enjoy the simple pricing of our reliable short- and long-codes, and never pay longer than your actual calling time. We bill by the second.',
    LANDING_TITLE_28: 'NO HIDDEN COST',
    LANDING_TITLE_29: 'Start calling in minutes',
    LANDING_TITLE_30: 'VIRTUAL NUMBERS',
    LANDING_TITLE_31:
      'Allow customers to get in touch however they want. Our local and toll-free Numbers are compatible with all our communications solutions.',
    LANDING_TITLE_32: 'SCALABLE SIP TRUNKING',
    LANDING_TITLE_33:
      'Create as many virtual connections to the PSTN as you need with scalable SIP Trunking. Experience the benefits of fast and reliable VoIP.',
    LANDING_TITLE_34: 'VOICE API',
    LANDING_TITLE_35:
      'Program Voice with our API. Send outbound messages, configure call flows for inbound calls, and get insights into your Voice calls.',
    Review_block_Title: 'VOICE PLATFORM',
    Review_block_Desc: 'Engage customers on their preferred channels using a single platform',
    Review_block_1_Title: 'WORLDWIDE COVERAGE',
    Review_block_1_Desc: 'Improve your users experience from every region you will deside to work.',
    Review_block_2_Title: 'STABLE CONNECTION',
    Review_block_2_Desc: 'Modern real-time voice communications protocols allow users to connect in a click.',
    Review_block_3_Title: 'REGULATORY COMPLIANCE',
    Review_block_3_Desc: 'We help you comply with all applicable laws and regulations, including GDPR.',
    Review_block_4_Title: 'SECURITY FIRST',
    Review_block_4_Desc: 'Receive enterprise-grade security for your products, as a default.',
    Review_block_5_Title: 'WORLD CLASS SUPPORT',
    Review_block_5_Desc: '24/7 support for you and your team, with local presence in 3 countries.',
    Review_block_6_Title: 'FAIR PRICING PRACTICES',
    Review_block_6_Desc: 'The more you send, the less you pay. Contact our sales team for a custom plan.',
    Review_block_Button: 'CONTACT SALES',
    WORK_Title: 'OUR WORK',
    WORK_Desc: 'Trusted by many gamers worldwide',
    PLINK_Title: 'PLINK',
    PLINK_Desc: `It's the first matchmaking platform made especially for gamers. Communicate, using free voice chat, find friends and check what games your friends play. Call up, prepare to play, find worthy opponents and track the game progress of your friends.`,
    WARFACE_Title: 'Warface',
    WARFACE_Desc: `Warface - is a free online shooter with diverse PVP modes, exciting PVE missions, outstanding arsenal and millions of fans around the world! Enlist in the army consisting of more than 40,000,000 players worldwide.`,
    Security_block_Title: 'SECURITY',
    Security_block_Desc: 'Keep your data safe',
    // LANDING_TITLE_59:
    //   'We count hospitals, banks and even governments amongst our clients because we are serious about security and privacy. Close attention to local rules and regulations, employee-screening, and data encryption are just a few ways we ensure absolute security and reliability.',
    Security_block_Hosting_Title: 'HOSTING',
    Security_block_Hosting_Desc:
      'The latest secure TLS connection sends your messages to our platform. This platform is hosted in two Tier 3 data centers providing full redundancy. When one data center disconnects, the second takes over all traffic. All data is backed up on a daily basis in a third data center.',
    Security_block_Software_Title: 'SOFTWARE',
    Security_block_Software_Desc:
      'We always follow latest security development guides, we control third-party software versions, the usage of software with known vulnerabilities is totally disabled, we control runtime environment and versions, we use static code analysis tools and penetration testing as a part of development process, and more. We care.',
    Security_block_Monitoring_Title: 'MONITORING',
    Security_block_Monitoring_Desc:
      'We monitor the worldwide performance of our service 24 hours a day, 7 days a week, and our SLA guarantees 99.95% uptime. On the rare occasion that a server goes down or another problem pops up, we directly receive an alert. Our team will take action immediately.',
    Security_block_Button: 'CONTACT SALES',
    LANDING_TITLE_67: 'SONUS (C) 2019-2022',

    LANDING_TITLE_067: 'OUR SOLUTIONS',
    LANDING_TITLE_069: 'MOBILE GAMES',
    LANDING_TITLE_070:
      'Create custom Voice applications with our Voice API for Mobile platforms. Integrate our lightweight solution to your app and make it more reliable for users.',
    LANDING_TITLE_071: 'PC GAMES',
    LANDING_TITLE_071_1: 'CONSOLE GAMES',
    LANDING_TITLE_072_1:
      'Create custom Voice applications with our Voice API for any consoles. Engage your users on portable consoles or while they play at home.',
    LANDING_TITLE_072:
      'Create custom Voice applications with our Voice API for Desktop. Use all features to engage your customers on PC.',
    LANDING_TITLE_073: 'Supporting both platform:',
    LANDING_TITLE_074: 'iOS and Android',
    LANDING_TITLE_075: 'Supporting all consoles:',
    LANDING_TITLE_076: 'PlayStation, XBOX and Nintendo',
    LANDING_TITLE_077: 'OUR DISTRIBUTION',
    LANDING_TITLE_078: 'Engage customers on their preferred channels using a single platform',
    LANDING_TITLE_079: 'IN-GAME VOICE CHATS',
    LANDING_TITLE_080:
      'Voice chats helps to make your game more interesting and makes the users come back to your product every day.',
    LANDING_TITLE_081: 'DESKTOP & WEB APPLICATIONS',
    LANDING_TITLE_082:
      'No matter whether your users are a tough PC gamer or not - they need voice communication everywhere.',
    LANDING_TITLE_083: `MOBILE APP'S`,
    LANDING_TITLE_084: 'Voice communication never left our phones, even when we are playing on mobile now.',
    LANDING_TITLE_068: 'Multiplatform support',
    LANDING_TITLE_VOICE_1: 'Solution in action',
    LANDING_TITLE_VOICE_2: 'Join group call now',
    LANDING_ROOM_NAME: 'Sonus Demo Call',
    LANDING_BUTTON_JOIN_ROOM: 'Join the room',
    LANDING_BUTTON_MUTE_MICRO: 'Mute micro',
    LANDING_BUTTON_LEAVE_ROOM: 'Leave the room',
    DASHBOADRD_TITLE: 'DASHBOARD',
    DASHBOADRD_DESC: 'Easy navigate via our interactive Dashboard',
    DASHBOARD_FEATURES_TITLE: 'All necessary information in one place:',
    DASHBOARD_FEATURES_1: 'Acess to Wiki and FAQ',
    DASHBOARD_FEATURES_2: 'Get all the data you need: PCU, daily spend, budget, sessions, etc.',
    DASHBOARD_FEATURES_3: 'Check your in-game communication services availability',
    DASHBOARD_FEATURES_4: 'Add payment methods: Credit Card, Paypal, Invoice, etc.',
    DASHBOARD_FEATURES_5: 'Fault alert service',
    CHAT_TITLE: 'CHAT FEATURES',
    CHAT_DESC: 'Use our solutions for your product',
    CHAT_SCREEN_1: 'Typing indicators',
    CHAT_SCREEN_2: 'P2P and Group Chats',
    CHAT_SCREEN_3: 'Mute and Ban users',
    CHAT_FEATURE_TITLE_1: 'Realiable Chat-as-a-Service',
    CHAT_FEATURE_DESC_1: 'No need to host the solution, just use the cloud service',
    CHAT_FEATURE_TITLE_2: 'Flexible Chat API Server',
    CHAT_FEATURE_DESC_2: 'Use & Support the only necessary features to launch faster',
    CHAT_FEATURE_TITLE_3: 'Live Events',
    CHAT_FEATURE_DESC_3: 'Receive alerts/updates on all user changes and actions',
    CHAT_FEATURE_TITLE_4: 'Ready-to-run chat UI Kit',
    CHAT_FEATURE_DESC_4: 'No need to develop your own chat interface',
  },
  en: {
    HEADER_TITLE: 'СОНУС',
    HEADER1: 'Product',
    HEADER2: `For Dev's`,
    HEADER3: 'Platform',
    HEADER4: 'Our Work',
    HEADER5: 'Security',

    MENU_ADVANTAGES: 'Advantages',
    MENU_OVERVIEW: 'Overview',
    MENU_INTEGRATION: 'Integration',
    MENU_DASHBOARD: 'Dashboard',
    MENU_COMPETITORS: 'Competitors',
    MENU_PRICING: 'Pricing',
    MENU_CONTACTS: 'Contacts',

    CHANGE_LOCALE: 'English ver.',
    HEADER_CLIENTS: 'Current clients',

    ADVANTAGE_TITLE: 'Advantages',
    ADVANTAGE_1: 'Seemless integration',
    ADVANTAGE_2: 'Dashboard',
    ADVANTAGE_3: 'Cross-Platform sdk',
    ADVANTAGE_4: 'Service availability',

    ADVANTAGE_DESC_1: 'Native SDK for every supported platform',
    ADVANTAGE_DESC_2: 'Track the live heartbeat of your players communications',
    ADVANTAGE_DESC_3: 'Unity, Unreal, CryEngine, Lumberyard, iOS, Android, Windows, MacOS',
    ADVANTAGE_DESC_4: '99.995% SLA',

    INTEGRATION_TITLE: 'Integration',
    INTEGRATION_1: 'Dedicated Integration Team',
    INTEGRATION_2: 'Default UX/UI toolkit or fully customizable UX/UI SDK',
    INTEGRATION_3: 'Multi-SDK provider support: use your existing VoiceCom solution alongside Sonus SDK',
    INTEGRATION_4: '24/7 Support',
    INTEGRATION_5: 'Dedicated Account Manager',
    INTEGRATION_6: 'Unlimited Infrastucture Scalability',

    PRICING_TITLE: 'Pricing',
    PRICING_FREE: 'Free of charge',
    PRICING_FREE_DESC1: 'Less than 10k',
    PRICING_FREE_DESC2: 'AVERAGE MONTHLY DAU',
    PRICING_ENTERPRISE1: 'Enterprise 10k+',
    PRICING_ENTERPRISE2: 'AVERAGE MONTHLY DAU',
    PRICING_ENTERPRISE_DESC1: '$330 per 1k',
    PRICING_ENTERPRISE_DESC2: 'AVERAGE MONTHLY DAU',

    Main_header: 'SONUS — the next-gen voicecom chat for game developers',
    Main_Desc: `Introducing the advanced technology to support your game or project.`,
    Main_Button: 'TRY NOW',
    Platforms_Title: 'PLATFORM',
    Platforms_Desc: 'Engage users on their preferred devices using a single solution',
    Code_block_Title: 'INTEGRATE IN MINUTES',
    Code_block_Desc: 'A few lines of code that scale our user base to the moon.',
    LANDING_TITLE_8: 'OUR PERFOMANCE',
    LANDING_TITLE_9: 'Competitive matrix',
    LANDING_TITLE_10: 'Technology',
    LANDING_TITLE_11: 'WebRTC',
    LANDING_TITLE_12: 'SIP',
    LANDING_TITLE_13: 'Cross-Platform',
    features_1: [
      {
        name: 'Windows',
        sonus: 'Yes',
        vivox: 'Yes'
      },
      {
        name: 'MacOS',
        sonus: 'Yes',
        vivox: 'Yes'
      },
      {
        name: 'PS4',
        sonus: 'Yes',
        vivox: 'Yes'
      },
      {
        name: 'XBOX',
        sonus: 'No',
        vivox: 'Yes'
      },
      {
        name: 'Android',
        sonus: 'Yes',
        vivox: 'Yes'
      },
      {
        name: 'iOS',
        sonus: 'Yes',
        vivox: 'Yes'
      },
      {
        name: 'Web',
        sonus: 'Yes',
        vivox: 'Yes'
      },
      {
        name: 'Nintendo Switch',
        sonus: 'Yes',
        vivox: 'Yes'
      }
    ],
    features_2: [
      {
        selected: true,
        name: 'Remote Call Control',
        sonus: 'Yes',
        vivox: 'No'
      },
      {
        name: 'Voice Detection',
        sonus: 'Yes',
        vivox: 'Yes'
      },
      {
        selected: true,
        name: '"Hot" Devices Switch',
        sonus: 'Yes',
        vivox: 'No'
      },
      {
        name: 'Personal Mute',
        sonus: 'Yes',
        vivox: 'Yes'
      },
      {
        name: 'Push To Talk',
        sonus: 'Yes',
        vivox: 'Yes'
      },
      {
        name: 'Custom Mic Level',
        sonus: 'Yes',
        vivox: 'Yes'
      },
      {
        name: 'Reconnect',
        sonus: 'Yes',
        vivox: 'Yes'
      },
      {
        selected: true,
        name: 'Fault Tolerance',
        sonus: 'Yes',
        vivox: 'Yes'
      },
      {
        name: 'Horizontal Scaling',
        sonus: 'Yes',
        vivox: 'Yes'
      },
      {
        selected: true,
        name: 'P2P Calls',
        sonus: 'Yes',
        vivox: 'Yes'
      },
      {
        name: 'Text Messaging',
        sonus: 'Yes',
        vivox: 'Yes'
      }
    ],
    // LANDING_TITLE_8:
    //   'Powerful dashboard tools enable you to tailor customer journeys that previously required programming expertise or expensive hardware.',
    // LANDING_TITLE_9: 'Extensive API documentation',
    // LANDING_TITLE_10: 'Libraries for popular languages',
    // LANDING_TITLE_11: 'Intuitive, easy-to-use tutorials',
    Overview_block_Features: 'FEATURES',
    Overview_block_Features_Desc: 'Experience voice services like never before',
    Overview_block_Features_First_Title: 'STABLE CONNECTION',
    Overview_block_Features_First_Desc:
      'Every call deserves the best route. With our one-to-one or groups calls SmartRouting engine, we always find the fastest stable connection for your users in the different locations.',
    Overview_block_Features_Second_Title: 'OPTIMAL CONFIGURATIONS',
    Overview_block_Features_Second_Desc: `Responsive real-time voice detection prevents appearing of background noise and other disturbances for the best voice experience for all users inside your project.`,
    Overview_block_Features_Third_Title: 'COMPLETE SET',
    Overview_block_Features_Third_Desc:
      'Mute the sound personally for every participant, switch a call between the devices on the go, create groups up to 20 people, control your PC call from mobile remotely and more.',
    LANDING_TITLE_20: 'QUALITY',
    LANDING_TITLE_21: 'The infrastructure that makes the difference',
    LANDING_TITLE_22: 'CALL LIKE A LOCAL FROM ANYWHERE',
    LANDING_TITLE_23:
      'Do business like a local company anywhere in the world. Get a reliable caller ID and use our local or toll-free Numbers to create localized experiences.',
    LANDING_TITLE_24: 'VOICE CAPABILITIES',
    LANDING_TITLE_25:
      'Create custom Voice applications with our Voice API. Add menus to your call flow with IVR or protect user’s privacy with masked numbers.',
    LANDING_TITLE_26: 'NO HIDDEN COST',
    LANDING_TITLE_27:
      'Enjoy the simple pricing of our reliable short- and long-codes, and never pay longer than your actual calling time. We bill by the second.',
    LANDING_TITLE_28: 'NO HIDDEN COST',
    LANDING_TITLE_29: 'Start calling in minutes',
    LANDING_TITLE_30: 'VIRTUAL NUMBERS',
    LANDING_TITLE_31:
      'Allow customers to get in touch however they want. Our local and toll-free Numbers are compatible with all our communications solutions.',
    LANDING_TITLE_32: 'SCALABLE SIP TRUNKING',
    LANDING_TITLE_33:
      'Create as many virtual connections to the PSTN as you need with scalable SIP Trunking. Experience the benefits of fast and reliable VoIP.',
    LANDING_TITLE_34: 'VOICE API',
    LANDING_TITLE_35:
      'Program Voice with our API. Send outbound messages, configure call flows for inbound calls, and get insights into your Voice calls.',
    Review_block_Title: 'VOICE PLATFORM',
    Review_block_Desc: 'Engage customers on their preferred channels using a single platform',
    Review_block_1_Title: 'WORLDWIDE COVERAGE',
    Review_block_1_Desc: 'Improve your users experience from every region you will deside to work.',
    Review_block_2_Title: 'STABLE CONNECTION',
    Review_block_2_Desc: 'Modern real-time voice communications protocols allow users to connect in a click.',
    Review_block_3_Title: 'REGULATORY COMPLIANCE',
    Review_block_3_Desc: 'We help you comply with all applicable laws and regulations, including GDPR.',
    Review_block_4_Title: 'SECURITY FIRST',
    Review_block_4_Desc: 'Receive enterprise-grade security for your products, as a default.',
    Review_block_5_Title: 'WORLD CLASS SUPPORT',
    Review_block_5_Desc: '24/7 support for you and your team, with local presence in 3 countries.',
    Review_block_6_Title: 'FAIR PRICING PRACTICES',
    Review_block_6_Desc: 'The more you send, the less you pay. Contact our sales team for a custom plan.',
    Review_block_Button: 'CONTACT SALES',
    WORK_Title: 'OUR WORK',
    WORK_Desc: 'Trusted by many gamers worldwide',
    PLINK_Title: 'PLINK',
    PLINK_Desc: `It's the first matchmaking platform made especially for gamers. Communicate, using free voice chat, find friends and check what games your friends play. Call up, prepare to play, find worthy opponents and track the game progress of your friends.`,
    WARFACE_Title: 'Warface',
    WARFACE_Desc: `Warface - is a free online shooter with diverse PVP modes, exciting PVE missions, outstanding arsenal and millions of fans around the world! Enlist in the army consisting of more than 40,000,000 players worldwide.`,
    Security_block_Title: 'SECURITY',
    Security_block_Desc: 'Keep your data safe',
    // LANDING_TITLE_59:
    //   'We count hospitals, banks and even governments amongst our clients because we are serious about security and privacy. Close attention to local rules and regulations, employee-screening, and data encryption are just a few ways we ensure absolute security and reliability.',
    Security_block_Hosting_Title: 'HOSTING',
    Security_block_Hosting_Desc:
      'The latest secure TLS connection sends your messages to our platform. This platform is hosted in two Tier 3 data centers providing full redundancy. When one data center disconnects, the second takes over all traffic. All data is backed up on a daily basis in a third data center.',
    Security_block_Software_Title: 'SOFTWARE',
    Security_block_Software_Desc:
      'We always follow latest security development guides, we control third-party software versions, the usage of software with known vulnerabilities is totally disabled, we control runtime environment and versions, we use static code analysis tools and penetration testing as a part of development process, and more. We care.',
    Security_block_Monitoring_Title: 'MONITORING',
    Security_block_Monitoring_Desc:
      'We monitor the worldwide performance of our service 24 hours a day, 7 days a week, and our SLA guarantees 99.95% uptime. On the rare occasion that a server goes down or another problem pops up, we directly receive an alert. Our team will take action immediately.',
    Security_block_Button: 'CONTACT SALES',
    LANDING_TITLE_67: 'SONUS (C) 2019-2022',

    LANDING_TITLE_067: 'OUR SOLUTIONS',
    LANDING_TITLE_069: 'MOBILE GAMES',
    LANDING_TITLE_070:
      'Create custom Voice applications with our Voice API for Mobile platforms. Integrate our lightweight solution to your app and make it more reliable for users.',
    LANDING_TITLE_071: 'PC GAMES',
    LANDING_TITLE_071_1: 'CONSOLE GAMES',
    LANDING_TITLE_072_1:
      'Create custom Voice applications with our Voice API for any consoles. Engage your users on portable consoles or while they play at home.',
    LANDING_TITLE_072:
      'Create custom Voice applications with our Voice API for Desktop. Use all features to engage your customers on PC.',
    LANDING_TITLE_073: 'Supporting both platform:',
    LANDING_TITLE_074: 'iOS and Android',
    LANDING_TITLE_075: 'Supporting all consoles:',
    LANDING_TITLE_076: 'PlayStation, XBOX and Nintendo',
    LANDING_TITLE_077: 'OUR DISTRIBUTION',
    LANDING_TITLE_078: 'Engage customers on their preferred channels using a single platform',
    LANDING_TITLE_079: 'IN-GAME VOICE CHATS',
    LANDING_TITLE_080:
      'Voice chats helps to make your game more interesting and makes the users come back to your product every day.',
    LANDING_TITLE_081: 'DESKTOP & WEB APPLICATIONS',
    LANDING_TITLE_082:
      'No matter whether your users are a tough PC gamer or not - they need voice communication everywhere.',
    LANDING_TITLE_083: `MOBILE APP'S`,
    LANDING_TITLE_084: 'Voice communication never left our phones, even when we are playing on mobile now.',
    LANDING_TITLE_068: 'Multiplatform support',
    LANDING_TITLE_VOICE_1: 'Solution in action',
    LANDING_TITLE_VOICE_2: 'Join group call now',
    LANDING_ROOM_NAME: 'Sonus Demo Call',
    LANDING_BUTTON_JOIN_ROOM: 'Join the room',
    LANDING_BUTTON_MUTE_MICRO: 'Mute micro',
    LANDING_BUTTON_LEAVE_ROOM: 'Leave the room',
    DASHBOADRD_TITLE: 'DASHBOARD',
    DASHBOADRD_DESC: 'Easy navigate via our interactive Dashboard',
    DASHBOARD_FEATURES_TITLE: 'All necessary information in one place:',
    DASHBOARD_FEATURES_1: 'Acess to Wiki and FAQ',
    DASHBOARD_FEATURES_2: 'Get all the data you need: PCU, daily spend, budget, sessions, etc.',
    DASHBOARD_FEATURES_3: 'Check your in-game communication services availability',
    DASHBOARD_FEATURES_4: 'Add payment methods: Credit Card, Paypal, Invoice, etc.',
    DASHBOARD_FEATURES_5: 'Fault alert service',
    CHAT_TITLE: 'CHAT FEATURES',
    CHAT_DESC: 'Use our solutions for your product',
    CHAT_SCREEN_1: 'Typing indicators',
    CHAT_SCREEN_2: 'P2P and Group Chats',
    CHAT_SCREEN_3: 'Mute and Ban users',
    CHAT_FEATURE_TITLE_1: 'Realiable Chat-as-a-Service',
    CHAT_FEATURE_DESC_1: 'No need to host the solution, just use the cloud service',
    CHAT_FEATURE_TITLE_2: 'Flexible Chat API Server',
    CHAT_FEATURE_DESC_2: 'Use & Support the only necessary features to launch faster',
    CHAT_FEATURE_TITLE_3: 'Live Events',
    CHAT_FEATURE_DESC_3: 'Receive alerts/updates on all user changes and actions',
    CHAT_FEATURE_TITLE_4: 'Ready-to-run chat UI Kit',
    CHAT_FEATURE_DESC_4: 'No need to develop your own chat interface',
  },
}
