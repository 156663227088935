import React, { useRef, useState } from 'react' // {useEffect} was removed 
import '../App.css'
import Header from './Header'
import Footer from './Footer'
import vid from '../assets/1.mp4'
import favicon from '../assets/favicon.ico'
import { Helmet } from 'react-helmet'
// import { NavLink } from 'react-router-dom'
// import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
// import { useDispatch } from 'react-redux'
// import { loadLeaderboard, changeLocale } from '../actions'
import languages from '../etc/languages'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import imgDashboard from '../assets/dashboard.png';
import chatScreen1 from '../assets/chat-screen_1.png';
import chatScreen2 from '../assets/chat-screen_2.png';
import chatScreen3 from '../assets/chat-screen_3.png';

export const NoMatch = ({ token, user, plinkContext }) => {
  // locale: state.locale
  const locale = useSelector(state => state.locale)
  const [burger, setBurger] = useState(false)
  // dispatch(changeLocale(id))
  const handleBurger = () => {
    setBurger(!burger)
  }

  const audioPlayer = useRef(null);
  const group1 = useRef(null);
  const group2 = useRef(null);
  const muteIcon = useRef(null);
  const userList = useRef(null);
  const [activeCall, setActiveCall] = useState(false); //fake warning here
  const [muted, setMuted] = useState(false);
  const WebRTC = window.WebRTC;
  
  const handleJoin = () => {
    audioPlayer.current.controls = true;
    WebRTC.joinCall(audioPlayer.current, (err, stream) => {
      audioPlayer.current.srcObject = stream;
    });
    audioPlayer.current.play();
    setActiveCall(true);
    group1.current.classList.add("displayNone");
    group2.current.classList.remove("displayNone");
    muteIcon.current.classList.remove("fa-microphone-slash");
    setMuted(false);
    handleUserJoined();
  }

  const handleLeave = () => {
    WebRTC.leaveCall();
    audioPlayer.current.srcObject = null;
    setActiveCall(false);
    group1.current.classList.remove("displayNone");
    group2.current.classList.add("displayNone");
    handleUserLeft();
    userList.current.innerHTML = '';
  }

  const HandleMute = () => {
    setMuted(!muted);
    WebRTC.toggleMedia(muted);
    muteIcon.current.classList.toggle("fa-microphone-slash");
  }

  const handleUserJoined = ()=> {
    var divNode = document.createElement('div');
    divNode.appendChild(document.createTextNode("Connected"));
    userList.current.appendChild(divNode);
  }
  
  const handleUserLeft = ()=> {
    let elementToRemove = userList.current.lastChild;
    if(elementToRemove && userList.current){
        userList.current.removeChild(elementToRemove);
    }
  }

  WebRTC.onReady(() => {
    console.log("WebRTC onReady");
  });

  WebRTC.onUserJoined(handleUserJoined);
  WebRTC.onUserLeaved(handleUserLeft);

  return (
    <div className={`wave_container`}>
      <Helmet>
        <link rel='shortcut icon' href={favicon} />
      </Helmet>
      <Header handleBurger={handleBurger}></Header>
      {burger ? (
        <div
          style={{
            position: 'fixed',
            top: '0px',
            left: '0px',
            right: '0px',
            bottom: '0px',
            zIndex: '100',
            backgroundColor: '#141425',
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'space-between'
          }}
          className={'wave_container--burgerMenu'}
        >
          <div
            onClick={handleBurger}
            style={{ left: '20px', top: '20px', fontSize: '16px' }}
            className='HeaderF1 absolute flex aiCenter clickable HeaderMflex'
          >
            <div className='LandingBurgIcon'></div>
          </div>
          <div className='flex aiCenter jcBetween flex1 fColumn LandingBg burgerMenuWrap'>
            <div className='flex aiCenter aiCenter jcBetween flex1 fColumn wide'>
              <a onClick={() => setBurger(false)} href='#advantages' className='HeaderF2 flex aiCenter jcCenter flex1'>
                {languages[locale].MENU_ADVANTAGES}
              </a>
              <a onClick={() => setBurger(false)} href='#overview' className='HeaderF2 flex aiCenter jcCenter flex1'>
                {languages[locale].MENU_OVERVIEW}
              </a>
              <a onClick={() => setBurger(false)} href='#integration' className='HeaderF2 flex aiCenter jcCenter flex1'>
                {languages[locale].MENU_INTEGRATION}
              </a>
              <a onClick={() => setBurger(false)} href='#dashboard' className='HeaderF2 flex aiCenter jcCenter flex1'>
                {languages[locale].MENU_DASHBOARD}
              </a>
              <a onClick={() => setBurger(false)} href='#competitors' className='HeaderF2 flex aiCenter jcCenter flex1'>
                {languages[locale].MENU_COMPETITORS}
              </a>
              <a onClick={() => setBurger(false)} href='#pricing' className='HeaderF2 flex aiCenter jcCenter flex1'>
                {languages[locale].MENU_PRICING}
              </a>
              <div className='burgerMenu__SocialsLinks'>
                <div className="socialLink"><a target='_blank' href='https://www.linkedin.com/company/sonus-io/'/></div>
                <div className="socialLink"><a target='_blank' href='https://twitter.com/Sonus__'/></div>
              </div>

            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      <div className='flex aiCenter jcCenter LandingBg relative headerHeightAccounting'>
        <div className='vidWrapper relative flex aiCenter jcCenter'>
          
          <video autoPlay muted loop className='vid'>
            <source src={vid} type='video/mp4' />
          </video>
         
        </div>
        <div className='flex aiCenter jcCenter fColumn wide'>
          <div className='LandingF1 LandingFWide headerTitle'>
            {languages[locale].Main_header}
          </div>
          {/* 
          <div className='LandingF2 mtop16 LandingFWide600'>{languages[locale].Main_Desc}</div>
          */}
          <a
            href='#democall'
            // target='_blank' rel="noopener noreferrer"
            className='LandingButton1 clickable flex aiCenter jcCenter LandingF3 headerTryButton'
          >
            {languages[locale].Main_Button}
          </a>
          <div className="headerClients">
            <div>{languages[locale].HEADER_CLIENTS}</div>
            <div className="flex aiCenter jcCenter clentsLogo">
              <a href="https://plink.gg" target="blank" className="plinkClient"></a>
              <a href="https://wf.mail.ru/" target="blank"  className="warfaceClient"></a>
            </div>
          </div>
        </div>
      </div>

      <div id="advantages" className='flex aiCenter jcCenter LandingBg'>
        <div className='flex aiCenter jcCenter fColumn wide w100'>
          <div id='hedHed' style={{ marginTop: '96px' }} className='LandingF4 LandingFWide'>
            {languages[locale].Platforms_Title}
          </div>
          <div className='LandingF5 LandingFWide mtop8'>{languages[locale].Platforms_Desc}</div>
          <div className='mflex aiCenter flexWrap LandingBlockWrapSize LandingBlockWrap1 mtop24'>
            <div className='mflex1 LandingBlock mtop32 LandingBlock1'></div>
            <div className='mflex1 LandingBlock mmtop32 LandingBlock2 mleft12'></div>
            <div className='mflex1 LandingBlock mmtop32 LandingBlock3 mleft12'></div>
            <div className='mflex1 LandingBlock mmtop32 LandingBlock4 mleft12'></div>
            <div className='mflex1 LandingBlock mmtop32 LandingBlock5 mleft12'></div>
          </div>
          <h3 className="advantagesTitle">{languages[locale].ADVANTAGE_TITLE}</h3>
          <div className='chatFeaturesContainer advantages'>
            <div className="flex flex1">
              <div className="adv_icon1"></div>
              <div className="chatFeatureTitle">{languages[locale].ADVANTAGE_1}</div>
              <div className="chatFeatureDesc">{languages[locale].ADVANTAGE_DESC_1}</div>
            </div>
            <div className="flex flex1">
              <div className="adv_icon2"></div>
              <div className="chatFeatureTitle">{languages[locale].ADVANTAGE_2}</div>
              <div className="chatFeatureDesc">{languages[locale].ADVANTAGE_DESC_2}</div>
            </div>
            <div className="flex flex1">
              <div className="adv_icon3"></div>
              <div className="chatFeatureTitle">{languages[locale].ADVANTAGE_3}</div>
              <div className="chatFeatureDesc">{languages[locale].ADVANTAGE_DESC_3}</div>
            </div>
            <div className="flex flex1">
              <div className="adv_icon4"></div>
              <div className="chatFeatureTitle">{languages[locale].ADVANTAGE_4}</div>
              <div className="chatFeatureDesc">{languages[locale].ADVANTAGE_DESC_4}</div>
            </div>
          </div>
        </div>
      </div>
{/* 
      <div className='flex aiCenter jcCenter LandingBg'>
        <div className='flex aiCenter jcCenter fColumn wide'>
          <div className='LandingF4 LandingFWide mtop24 mm0'>{languages[locale].LANDING_TITLE_067}</div>
          <div className='LandingF5 LandingFWide mtop8'>{languages[locale].LANDING_TITLE_068}</div>
          <div className='flex flexWrap LandingBlockWrap1 mtop24 LandingBlockWrapSize3 jcCenter'>
            <div className='LandingPoop flex fColumn aiCenter relative'>
              <div className='Landingpop1 mhid' style={{ position: 'absolute', left: '-65.7px', top: '42.4px' }}></div>
              <div className='Landingpop2 mhid' style={{ position: 'absolute', right: '50.7px', top: '422.4px' }}></div>
              <div className='LandingPoopImg LandingPoopImg1 mtop20'></div>
              <div className='LandingPoopContent mtop24'>
                <div className='LandingPoopF1'>{languages[locale].LANDING_TITLE_069}</div>
                <div className='LandingPoopF2 mtop16'>{languages[locale].LANDING_TITLE_070}</div>
                <div className='LandingPoopF2 mtop24'>{languages[locale].LANDING_TITLE_073}</div>
                <div className='LandingPoopF3 mtop8'>{languages[locale].LANDING_TITLE_074}</div>
              </div>
            </div>
            <div className='LandingPoop flex fColumn aiCenter relative'>
              <div className='Landingpop3 mhid' style={{ position: 'absolute', right: '25.7px', top: '425.4px' }}></div>
              <div className='LandingPoopImg LandingPoopImg2 mtop20'></div>
              <div className='LandingPoopContent mtop24'>
                <div className='LandingPoopF1'>{languages[locale].LANDING_TITLE_071}</div>
                <div className='LandingPoopF2 mtop16'>{languages[locale].LANDING_TITLE_072}</div>
              </div>
            </div>
            <div className='LandingPoop flex fColumn aiCenter relative'>
              <div className='Landingpop4 mhid' style={{ position: 'absolute', left: '30.7px', top: '-62.4px' }}></div>
              <div className='Landingpop5 mhid' style={{ position: 'absolute', left: '383.7px', top: '252.4px' }}></div>
              <div className='LandingPoopImg LandingPoopImg3 mtop20'></div>
              <div className='LandingPoopContent mtop24'>
                <div className='LandingPoopF1'>{languages[locale].LANDING_TITLE_071_1}</div>
                <div className='LandingPoopF2 mtop16'>{languages[locale].LANDING_TITLE_072_1}</div>
                <div className='LandingPoopF2 mtop24'>{languages[locale].LANDING_TITLE_075}</div>
                <div className='LandingPoopF3 mtop8'>{languages[locale].LANDING_TITLE_076}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
*/}
      <div id="overview" style={{ backgroundColor: '#141425' }} className='flex aiCenter jcCenter LandingBg mtop72 mm0'>
        <div className='flex aiCenter jcCenter fColumn wide'>
          <div className='LandingF4 LandingFWide mmtop96'>{languages[locale].LANDING_TITLE_077}</div>
          <div className='LandingF5 LandingFWide mtop8'>{languages[locale].LANDING_TITLE_078}</div>
          <div className='flex flexWrap LandingBlockWrap1 mtop24 LandingBlockWrapSize3 jcCenter LandingFWide1280'>
            <div className='LandingDoop flex fColumn aiCenter'>
              <div className='LandingDoopImg LandingDoopImg1 mtop20'></div>
              <div className='LandingDoopF1 mtop24'>{languages[locale].LANDING_TITLE_079}</div>
              <div className='LandingDoopF2 mtop16'>{languages[locale].LANDING_TITLE_080}</div>
            </div>
            <div className='LandingDoop1 flex fColumn aiCenter mtop36'>
              <div className='LandingDoopImg_1 LandingDoopImg2 mtop20'></div>
              <div className='LandingDoopF1 mtop24'>{languages[locale].LANDING_TITLE_081}</div>
              <div className='LandingDoopF2 mtop16'>{languages[locale].LANDING_TITLE_082}</div>
            </div>
            <div className='LandingDoop1 flex fColumn aiCenter mtop36 mmleft36'>
              <div className='LandingDoopImg_1 LandingDoopImg3 mtop20'></div>
              <div className='LandingDoopF1 mtop24'>{languages[locale].LANDING_TITLE_083}</div>
              <div className='LandingDoopF2 mtop16'>{languages[locale].LANDING_TITLE_084}</div>
            </div>
          </div>

          <div id="integration" className='flex aiCenter jcCenter LandingBg integration'>
            <div className='flex aiCenter jcCenter fColumn wide'>
                <h3 className="integrationTitle">
                  {languages[locale].INTEGRATION_TITLE}
                </h3>
              <div className='flex LandingF5 mtop8 integrationContainer'>
                <div className="">{languages[locale].INTEGRATION_1}</div>
                <div className="">{languages[locale].INTEGRATION_2}</div>
                <div className="">{languages[locale].INTEGRATION_3}</div>
                <div className="">{languages[locale].INTEGRATION_4}</div>
                <div className="">{languages[locale].INTEGRATION_5}</div>
                <div className="">{languages[locale].INTEGRATION_6}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex aiCenter jcCenter LandingBg_2 LandingBg3 voiceRoomSection">
        <div className="flex aiCenter jcCenter fColumn flexWrap LandingBlockWrap1">
          <div id='democall' className="LandingF4 LandingFWide">{languages[locale].LANDING_TITLE_VOICE_1}</div>
          <div className="LandingF5 LandingFWide mtop8">{languages[locale].LANDING_TITLE_VOICE_2}</div>
          <div className="roomContainer">
            <div className="roomTitle">{languages[locale].LANDING_ROOM_NAME}</div>
            <div className="roomBody">
              <audio id="audio" ref={audioPlayer} controls="" autoplay="" playsinline="playsinline"></audio>
              <div ref={group1} className="group1">
                <button onClick={handleJoin} className="joinroom">{languages[locale].LANDING_BUTTON_JOIN_ROOM}</button>
              </div>
              <div ref={group2} className="group2 displayNone">
                <div ref={userList} className="userList">
                  
                </div>
                <div className="buttons-group-2">
                  <button onClick={HandleMute} className="mutemicro">
                      <i ref={muteIcon} class="fas fa-microphone"></i>
                      {languages[locale].LANDING_BUTTON_MUTE_MICRO}
                  </button>
                  <button onClick={handleLeave} className="leaveroom">{languages[locale].LANDING_BUTTON_LEAVE_ROOM}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="competitors" className='flex aiCenter jcCenter LandingBg'>
        <div style={{ width: '100%' }} className='flex aiCenter jcCenter fColumn wide'>
          <div id='hedHed' style={{ marginTop: '96px' }} className='LandingF4 LandingFWide'>
            {languages[locale].LANDING_TITLE_8}
          </div>
          <div className='LandingF5 LandingFWide mtop8'>{languages[locale].LANDING_TITLE_9}</div>

          <div className='LandingTable mmtop56'>
            <div className='LandingTableWrapper flex fColumn'>
              <div className='LandingTableRow mhid'>
                <div className='flex flex2'></div>
                <div className='flex flex4 mmleft36'>
                  <div className='LandingTableIcons'>
                    <div className="sonusLogo"></div>
                    <div className="logoDivider"></div>
                    <div className="vivoxLogo"></div>
                    <div className="logoDivider"></div>
                    <div className="twilioLogo"></div>
                    <div className="logoDivider"></div>
                    <div className="agoraLogo"></div>
                  </div>
                </div>
              </div>
              <div className='LandingTableRow'>
                <div className='flex flex2 aiCenter'>{languages[locale].LANDING_TITLE_10}</div>
                <div className='flex flex4 mmleft36'>
                  <div style={{ color: '#ffffff' }} className='flex1 LandingTableCell'>
                    {languages[locale].LANDING_TITLE_11}
                  </div>
                  <div className='flex1 LandingTableCell'>{languages[locale].LANDING_TITLE_12}</div>
                </div>
              </div>
              <div className='LandingTableRowHr mtop16 mbottom16'></div>
              {languages[locale].features_1.map(e => (
                <div className='LandingTableRow'>
                  <div className='flex flex2 aiCenter jcStart '>{e.name}</div>
                  <div className='flex flex4 mmleft36'>
                    <div className={`flex1 LandingTableCell ${e.selected ? 'selected' : ''}`}>{e.sonus}</div>
                    <div className={`flex1 LandingTableCell ${e.selected ? 'selected' : ''}`}>{e.vivox}</div>
                    <div className={`flex1 LandingTableCell ${e.selected ? 'selected' : ''}`}>{e.twilio}</div>
                    <div className={`flex1 LandingTableCell ${e.selected ? 'selected' : ''}`}>{e.agora}</div>
                  </div>
                </div>
              ))}
              <div className='LandingTableRowHr mtop16 mbottom16'></div>
              {languages[locale].features_2.map(e => (
                <div className='LandingTableRow'>
                  <div className='flex flex2 aiCenter jcStart '>{e.name}</div>
                  <div className='flex flex4 mmleft36'>
                    <div className={`flex1 LandingTableCell ${e.selected ? 'selected' : ''}`}>{e.sonus}</div>
                    <div className={`flex1 LandingTableCell ${e.selected ? 'selected' : ''}`}>{e.vivox}</div>
                    <div className={`flex1 LandingTableCell ${e.selected ? 'selected' : ''}`}>{e.twilio}</div>
                    <div className={`flex1 LandingTableCell ${e.selected ? 'selected' : ''}`}>{e.agora}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <h3 id="pricing" className="priceTitle">{languages[locale].PRICING_TITLE}</h3>
          <div className="priceContainer">
            <div>
              <div className="priceT">
                  <div>
                    {languages[locale].PRICING_FREE}
                  </div>
              </div>
              <div className="priceD">
                <div>
                  {languages[locale].PRICING_FREE_DESC1}
                  <br />
                  {languages[locale].PRICING_FREE_DESC2}
                </div>
              </div>
            </div>
            <div>
              <div className="priceT">
                <div>
                  {languages[locale].PRICING_ENTERPRISE1}
                  <br />
                  {languages[locale].PRICING_ENTERPRISE2}
                </div>
              </div>
              <div className="priceD">
                <div>
                  {languages[locale].PRICING_ENTERPRISE_DESC1}
                  <br />
                  {languages[locale].PRICING_ENTERPRISE_DESC2}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div id="dashboard" className='flex aiCenter jcCenter LandingBg'>
        <div className='flex aiCenter jcCenter fColumn wide'>
          <div className='flex aiCenter jcCenter LandingBg'>
            <div className='flex aiCenter jcCenter fColumn wide'>
              <div style={{ marginTop: '96px' }} className='LandingF4 LandingFWide'>
                {languages[locale].DASHBOADRD_TITLE}
              </div>
              <div className='LandingF5 LandingFWide mtop8'>{languages[locale].DASHBOADRD_DESC}</div>
              <div className='dashboardImgWrapper'>
                <img className="dashboardImg" src={imgDashboard} />
              </div>
            </div>
          </div>

          <div className='flex aiCenter jcCenter LandingBg'>
            <div className='flex aiCenter jcCenter fColumn wide'>
                <h3 className="dashboardFeaturesTitle">
                  {languages[locale].DASHBOARD_FEATURES_TITLE}
                </h3>
              <div className='flex LandingF5 mtop8 dashboardFeaturesContainer'>
                <div className="flex1 dashboardFeaturesGroup">
                  <div>{languages[locale].DASHBOARD_FEATURES_1}</div>
                  <div>{languages[locale].DASHBOARD_FEATURES_2}</div>
                </div>
                <div className="flex1 dashboardFeaturesGroup">
                  <div>{languages[locale].DASHBOARD_FEATURES_3}</div>
                  <div>{languages[locale].DASHBOARD_FEATURES_5}</div>
                </div>
                <div className="flex1 dashboardFeaturesGroup">
                  <div>{languages[locale].DASHBOARD_FEATURES_4}</div>
                </div>
              </div>
            </div>
          </div>

          <div className='flex aiCenter jcCenter LandingBg'>
            <div className='flex aiCenter jcCenter fColumn wide'>
              <div style={{ marginTop: '96px' }} className='LandingF4 LandingFWide'>
                {languages[locale].CHAT_TITLE}
              </div>
              <div className='LandingF5 LandingFWide mtop8'>{languages[locale].CHAT_DESC}</div>
              <div className='chatScreensContainer'>
                <div className="flex flex1">
                  <div className="chatScreenDesc">{languages[locale].CHAT_SCREEN_1}</div>
                  <img src={chatScreen1} />
                </div>
                <div className="flex flex1">
                  <div className="chatScreenDesc">{languages[locale].CHAT_SCREEN_2}</div>
                  <img src={chatScreen2} />
                </div>
                <div className="flex flex1">
                  <div className="chatScreenDesc">{languages[locale].CHAT_SCREEN_3}</div>
                  <img src={chatScreen3} />
                </div>
              </div>
            </div>
          </div>

          <div id='hedCode' style={{ marginTop: '96px' }} className='LandingF4 LandingFWide'>
            {languages[locale].Code_block_Title}
          </div>
          <div style={{ minHeight: '40px' }} className='LandingF5 LandingFWide mtop8'>
            {languages[locale].Code_block_Desc}
          </div>
          <div className='flex jcCenter flexWrap mtop56'>
            <SyntaxHighlighter className='LandingBoard' language='java'>
              {`final String roomIdentifier = "/hunters/6c2c5755-8ed6-4812-bc3e-3a82fc465a57";
final String userName = "DealnoteDev";

VoiceCall call = new GroupVoiceCall(context, roomIdentifier, userName);
call.addCallListener(callListener);
call.connect();`}
            </SyntaxHighlighter>
            {/* <div className='mleft36 flex fColumn'>
              <div className='mtop16 LandingF6'>{languages[locale].LANDING_TITLE_8}</div>
              <div className='mtop24 flex'>
                <div className='LandingIconOk'></div>
                <div className='LandingF7 mleft24'>{languages[locale].LANDING_TITLE_9}</div>
              </div>
              <div className='mtop24 flex'>
                <div className='LandingIconOk'></div>
                <div className='LandingF7 mleft24'>{languages[locale].LANDING_TITLE_10}</div>
              </div>
              <div className='mtop24 flex'>
                <div className='LandingIconOk'></div>
                <div className='LandingF7 mleft24'>{languages[locale].LANDING_TITLE_11}</div>
              </div>
            </div> */}
          </div>
          <div className='mtop96 mflex jcStart LandingBlockWrapSize'>
            <div className='mmmleft32 LandingIcon2'></div>
            <div className='mmleft32 mmtop16 flex fColumn'>
              <div className='LandingF8'>{languages[locale].Overview_block_Features}</div>
              <div className='LandingF9 mtop4'>{languages[locale].Overview_block_Features_Desc}</div>
            </div>
          </div>
          <div className='mtop40 mmmleft32 mflex jcStart LandingBlockWrapSize'>
            <div className='mflex1'>
              <div className='LandingF10'>{languages[locale].Overview_block_Features_First_Desc}</div>
              <div className='LandingF11 mtop16'>{languages[locale].Overview_block_Features_First_Title}</div>
            </div>
            <div className='mflex1 mmleft36'>
              <div className='LandingF10'>{languages[locale].Overview_block_Features_Second_Desc}</div>
              <div className='LandingF11 mtop16'>{languages[locale].Overview_block_Features_Second_Title}</div>
            </div>
            <div className='mflex1 mmleft36'>
              <div className='LandingF10'>{languages[locale].Overview_block_Features_Third_Desc}</div>
              <div className='LandingF11 mtop16'>{languages[locale].Overview_block_Features_Third_Title}</div>
            </div>
          </div>
          {/* <div style={{ height: '1px', backgroundColor: '#3a3a69' }} className='mtop56 LandingBlockWrapSize'></div> */}
          {/* <div className='mtop56 mflex jcStart LandingBlockWrapSize'>
            <div className='mmmleft32 LandingIcon3'></div>
            <div className='mmleft32 flex fColumn'>
              <div className='LandingF8'>{languages[locale].LANDING_TITLE_20}</div>
              <div className='LandingF9 mtop4'>{languages[locale].LANDING_TITLE_21}</div>
            </div>
          </div> */}
          {/* <div className='mtop40 mmmleft32 mflex jcStart LandingBlockWrapSize'>
            <div className='mflex1'>
              <div className='LandingF10'>{languages[locale].LANDING_TITLE_23}</div>
              <div className='LandingF11 mtop16'>{languages[locale].LANDING_TITLE_22}</div>
            </div>
            <div className='mflex1 mmleft36'>
              <div className='LandingF10'>{languages[locale].LANDING_TITLE_25}</div>
              <div className='LandingF11 mtop16'>{languages[locale].LANDING_TITLE_24}</div>
            </div>
            <div className='mflex1 mmleft36'>
              <div className='LandingF10'>{languages[locale].LANDING_TITLE_27}</div>
              <div className='LandingF11 mtop16'>{languages[locale].LANDING_TITLE_26}</div>
            </div>
          </div> */}
          {/* <div style={{ height: '1px', backgroundColor: '#3a3a69' }} className='mtop56 LandingBlockWrapSize'></div> */}
          {/* <div className='mtop56 flex jcStart LandingBlockWrapSize'>
            <div className='LandingIcon4'></div>
            <div className='mleft32 flex fColumn'>
              <div className='LandingF8'>{languages[locale].LANDING_TITLE_28}</div>
              <div className='LandingF9 mtop4'>{languages[locale].LANDING_TITLE_29}</div>
            </div>
          </div> */}
          {/* <div style={{ marginBottom: '96px' }} className='mtop40 flex jcStart LandingBlockWrapSize'>
            <div className='flex1'>
              <div className='LandingF10'>{languages[locale].LANDING_TITLE_31}</div>
              <div className='LandingF11 mtop16'>{languages[locale].LANDING_TITLE_30}</div>
            </div>
            <div className='flex1 mleft36'>
              <div className='LandingF10'>{languages[locale].LANDING_TITLE_33}</div>
              <div className='LandingF11 mtop16'>{languages[locale].LANDING_TITLE_32}</div>
            </div>
            <div className='flex1 mleft36'>
              <div className='LandingF10'>{languages[locale].LANDING_TITLE_35}</div>
              <div className='LandingF11 mtop16'>{languages[locale].LANDING_TITLE_34}</div>
            </div>
          </div> */}
        </div>
      </div>

      <div id='hed2' style={{ backgroundColor: '#141425' }} className='flex aiCenter jcCenter LandingBg mtop96'>
        <div className='flex aiCenter jcCenter fColumn wide'>
          <div style={{ marginTop: '96px' }} className='LandingF4 LandingFWide'>
            {languages[locale].Review_block_Title}
          </div>
          <div className='LandingF5 LandingFWide mtop8'>{languages[locale].Review_block_Desc}</div>
          <div className='flex flexWrap LandingBlockWrap1 mtop32 LandingBlockWrapSize'>
            <div className='LandingPanel mtop24 flex1 flex fColumn'>
              <div className='flex aicenter mtop20 mleft20 mright20'>
                <div className='LandingImg LandingImg1'></div>
                <div className='mleft16 LandingF13 flex1'>{languages[locale].Review_block_1_Title}</div>
              </div>
              <div className='LandingF12 mtop12 mleft20 mright20'>{languages[locale].Review_block_1_Desc}</div>
            </div>
            <div className='LandingPanel mtop24 flex1'>
              <div className='flex aicenter mtop20 mleft20 mright20'>
                <div className='LandingImg LandingImg2'></div>
                <div className='mleft16 LandingF13 flex1'>{languages[locale].Review_block_2_Title}</div>
              </div>
              <div className='LandingF12 mtop12 mleft20 mright20'>{languages[locale].Review_block_2_Desc}</div>
            </div>
            <div className='LandingPanel mtop24 flex1'>
              <div className='flex aicenter mtop20 mleft20 mright20'>
                <div className='LandingImg LandingImg3'></div>
                <div className='mleft16 LandingF13 flex1'>{languages[locale].Review_block_3_Title}</div>
              </div>
              <div className='LandingF12 mtop12 mleft20 mright20'>{languages[locale].Review_block_3_Desc}</div>
            </div>
          </div>
          <div className='flex flexWrap LandingBlockWrap1 mtop16 LandingBlockWrapSize'>
            <div className='LandingPanel mtop24 flex1'>
              <div className='flex aicenter mtop20 mleft20 mright20'>
                <div className='LandingImg LandingImg4'></div>
                <div className='mleft16 LandingF13 flex1'>{languages[locale].Review_block_4_Title}</div>
              </div>
              <div className='LandingF12 mtop12 mleft20 mright20'>{languages[locale].Review_block_4_Desc}</div>
            </div>
            <div className='LandingPanel mtop24 flex1'>
              <div className='flex aicenter mtop20 mleft20 mright20'>
                <div className='LandingImg LandingImg5'></div>
                <div className='mleft16 LandingF13 flex1'>{languages[locale].Review_block_5_Title}</div>
              </div>
              <div className='LandingF12 mtop12 mleft20 mright20'>{languages[locale].Review_block_5_Desc}</div>
            </div>
            <div className='LandingPanel mtop24 flex1'>
              <div className='flex aicenter mtop20 mleft20 mright20'>
                <div className='LandingImg LandingImg6'></div>
                <div className='mleft16 LandingF13 flex1'>{languages[locale].Review_block_6_Title}</div>
              </div>
              <div className='LandingF12 mtop12 mleft20 mright20'>{languages[locale].Review_block_6_Desc}</div>
            </div>
          </div>
          <a
            href='mailto:sales@sonus.io'
            target='_blank' rel="noopener noreferrer"
            style={{ marginBottom: '96px' }}
            className='LandingButton1 clickable flex aiCenter jcCenter LandingF3 mtop56'
          >
            {languages[locale].Review_block_Button}
          </a>
        </div>
      </div>
      <div className='flex aiCenter jcCenter LandingBg LandingBg2'>
        <div className='flex aiCenter jcCenter fColumn wide'>
          <div id='hed3' style={{ marginTop: '96px' }} className='LandingF17 LandingFWide'>
            {languages[locale].WORK_Title}
          </div>
          <div className='LandingF18 mtop8 LandingFWide600'>{languages[locale].WORK_Desc}</div>
          <div style={{ marginBottom: '96px' }} className='mflex jcCenter mtop56 mleft32 mright32'>
            <div className='LandingBigblock flex flex1 fColumn aiCenter'>
              <div className='LandingPhoto LandingPhoto1 mtop40'></div>
              <div className='LandingF19 mtop32'>{languages[locale].PLINK_Title}</div>
              <div className='LandingF20 mtop16 mleft32 mright32' style={{ marginBottom: '16px' }}>{languages[locale].PLINK_Desc}</div>
            </div>
            <div className='LandingBigblock mleft56 mmleft36 mmtop16 flex flex1 fColumn aiCenter'>
              <div className='LandingPhoto LandingPhoto2 mtop40'></div>
              <div className='LandingF19 mtop32'>{languages[locale].WARFACE_Title}</div>
              <div className='LandingF20 mtop16 mleft32 mright32' style={{ marginBottom: '16px' }}>{languages[locale].WARFACE_Desc}</div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex aiCenter jcCenter LandingBg_2 LandingBg3'>
        <div className='flex aiCenter jcCenter fColumn flexWrap LandingBlockWrap1'>
          <div id='hed4' style={{ marginTop: '96px' }} className='LandingF4 LandingFWide'>
            {languages[locale].Security_block_Title}
          </div>
          <div className='LandingF5 LandingFWide mtop8'>{languages[locale].Security_block_Desc}</div>
          {/* <div  className='LandingF14 mtop8'>
            {languages[locale].LANDING_TITLE_59}
          </div> */}
          <div className='mflex aiCenter jcBetween flexWrap LandingBlockWrap1 mtop60 mright32 mm0'>
            <div className='LandingProc mtop60 mmleft32 flex1 relative'>
              <div
                style={{ top: '-40px', left: 'calc(50% - 40px)' }}
                className='absolute LandingLogo LandingLogo1'
              ></div>
              <div className='LandingF15 mtop72 flex aiCenter jcCenter'>
                {languages[locale].Security_block_Hosting_Title}
              </div>
              <div className='LandingF16 mtop16 mleft20 mright20'>{languages[locale].Security_block_Hosting_Desc}</div>
            </div>
            <div className='LandingProc mtop60 mmleft32 flex1 relative'>
              <div
                style={{ top: '-40px', left: 'calc(50% - 40px)' }}
                className='absolute LandingLogo LandingLogo2'
              ></div>
              <div className='LandingF15 mtop72 flex aiCenter jcCenter'>
                {languages[locale].Security_block_Software_Title}
              </div>
              <div className='LandingF16 mtop16 mleft20 mright20'>{languages[locale].Security_block_Software_Desc}</div>
            </div>
            <div className='LandingProc mtop60 mmleft32 flex1 relative'>
              <div
                style={{ top: '-40px', left: 'calc(50% - 40px)' }}
                className='absolute LandingLogo LandingLogo3'
              ></div>
              <div className='LandingF15 mtop72 flex aiCenter jcCenter'>
                {languages[locale].Security_block_Monitoring_Title}
              </div>
              <div className='LandingF16 mtop16 mleft20 mright20'>
                {languages[locale].Security_block_Monitoring_Desc}
              </div>
            </div>
          </div>
          <a
            href='mailto:sales@sonus.io'
            target='_blank' rel="noopener noreferrer"
            style={{ marginBottom: '164px' }}
            className='LandingButton1 clickable flex aiCenter jcCenter LandingF3 mtop56'
          >
            {languages[locale].Security_block_Button}
          </a>
        </div>
      </div>
      <Footer></Footer>
      {/* <div className="blend"></div> */}
    </div>
  )
}
