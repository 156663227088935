export default {
	"lol": {
        name: "League of Legends",
        poster: "poster",
        background: "bgLol",
        link: "lol",
        gameSlug: "75a92b43-0b3a-4677-935c-624f3c516591",
        ladder: "LOL LADDER",
        leaderboards: {
            tracking: ["LOL NICKNAME", "GAME SERVER", "TIER", "WINRATE, %"],
            trackingFilters: ["GAME SERVER", "TIER", "WINRATE, %"]
        },
        info: {
            title:"League of Legends. Статистика игроков и аккаунтов",
            h1:"League of Legends, статистика игроков",
            h2:"League of Legends в PLINK. Играем, общаемся, смотрим статистику игроков",
            text1: "Ролевая видеоигра с элементами стратегии в реальном времени (MOBA), разработанная и выпущенная компанией Riot Games. Игра распространяется по модели free-to-play. Ежемесячная аудитория игры составляет 100 млн игроков по всему миру.",
            text2: "Хочешь всегда выигрывать в LOL? Приложение PLINK найдёт тебе напарников в команду для LOL, с которыми ты сможешь поднять свою статистику и выигрывать матчи, а также в приложении ты сможешь посмотреть статистика по лолу.",
        },
        brawlers: false,
    },
    "fortnite": {
        name: "Fortnite",
        poster: "posterFort",
        background: "bgLol",
        link: "fortnite",
        gameSlug: "bab0671c-1986-4b60-81ce-8832708d7b23",
        ladder: "FORTNITE LADDER",
        leaderboards: {
            tracking: ["EPIC NICKNAME", "SCORE"],
            trackingFilters: ["SCORE"]
        },
        info: false,
        brawlers: false,
    },
    "blitz": {
        name: "World of Tanks: Blitz",
        poster: "posterBlitz",
        background: "bgLol",
        link: "blitz",
        gameSlug: "dac145bd-ef15-4455-945d-688bd0d481b1",
        ladder: "WORLD OF TANKS:BLITZ LADDER",
        leaderboards: {
            tracking: ["USERNAME", "WINRATE"],
            trackingFilters: ["WINRATE"]
        },
        info: false,
        brawlers: false,
    },
    "dota2": {
        name: "Dota 2",
        poster: "posterDota",
        background: "bgLol",
        link: "dota2",
        gameSlug: "5c0eaf5b-4d34-4611-ab66-33d3bb73887a",
        ladder: "DOTA 2 LADDER",
        leaderboards: {
            tracking: ["USERNAME", "WINRATE"],
            trackingFilters: ["WINRATE"]
        },
        info: {
            title:"DOTA 2. Статистика игроков и аккаунтов",
            h1:"DOTA 2 статистика в приложении PLINK",
            h2:"DOTA 2 stats",
            text1: "Компьютерная многопользовательская командная игра в жанре multiplayer online battle arena, разработанная Valve Corporation. Игра является продолжением DotA — пользовательской карты-модификации для игры Warcraft III. Игра изображает сражение на карте особого вида; в каждом матче участвуют две команды по пять игроков, управляющих «героями» — персонажами с различными наборами способностей.",
            text2: "Хочешь быстро прокачаться в Dota 2 и поднять себе MMR? Приложение Plink найдёт тебе достойных напарников в команду для доты, с которыми ты сможешь поднять MMR в Dota2, а также в приложении ты сможешь посмотреть статистика дота.",
        },
        brawlers: false,
    },
    "warface": {
        name: "Warface",
        poster: "posterWarface",
        background: "bgLol",
        link: "warface",
        gameSlug: "4d13812d-149c-4240-a743-454644d71bc4",
        ladder: "WARFACE LADDER",
        leaderboards: {
            tracking: ["USERNAME", "PVP WINS"],
            trackingFilters: ["PVP WINS"]
        },
        info: {
            title:"Warface. Статистика игроков и аккаунтов",
            h1:"Warface статистика в приложении PLINK",
            h2:"Warface в PLINK. Играем, общаемся, смотрим статистику игроков",
            text1: "Тактическая компьютерная игра в жанре массового многопользовательского онлайн-шутера от первого лица (MMOFPS). Игра разработана тремя студиями компании Crytek. Проект использует игровой движок CryEngine 3.5 и выпущен для персональных компьютеров.",
            text2: "Хочешь поднять рейтинг в Warface? Приложение Plink найдёт тебе напарников в команду для Warface, с которыми ты сможешь поднять свою статистику и выигрывать матчи, а также в приложении ты сможешь посмотреть статистика по варфейс.",
        },
        brawlers: false,
    },
    "overwatch": {
        name: "Overwatch",
        poster: "posterOver",
        background: "bgLol",
        link: "overwatch",
        gameSlug: "77b2f562-fe86-45c6-b722-ef1eca2e7806",
        ladder: "OVERWATCH LADDER",
        leaderboards: {
            tracking: ["BATTLE TAG", "RATING"],
            trackingFilters: ["RATING"]
        },
        info: false,
        brawlers: false,
    },
    "apex": {
        name: "Apex Legends (origin)",
        poster: "posterApex",
        background: "bgLol",
        link: "apex",
        gameSlug: "1ecbd1ed-7e04-4c38-92f0-b01ad0ecb853",
        ladder: "APEX LEGENDS LADDER",
        leaderboards: {
            tracking: ["NICKNAME", "KILLS"],
            trackingFilters: ["KILLS"]
        },
        info: {
            title:"Apex Legends. Статистика игроков и аккаунтов",
            h1:"Apex Legends",
            h2:"Apex Legends статистика",
            text1: "Покорите вершину славы в Apex Legends, бесплатном шутере в жанре королевской битвы, где легендарные персонажи с мощными умениями сражаются за звание лучших на всем Фронтире. Освойте стиль боя каждой легенды, число которых постоянно растет, а также тонкости тактической игры в отряде и смелые новшества, поднявшие планку жанра на новый уровень. Apex Legends статистика игрока доступна в PLINK с момента регистрации.",
            text2: "Находи товарищей по команде в апекс легендс, и делай поиск напарников в apex legends с помощью приложения PLINK. Добро пожаловать в королевскую битву следующего поколения. Скачай приложение прямо сейчас и будь одним из первых!",
        },
        brawlers: false,
    },
    "apex_ps": {
        name: "Apex Legends (playstation)",
        poster: "posterApexPs",
        background: "bgLol",
        link: "apex",
        gameSlug: "1ecbd1ed-7e04-4c38-92f0-b01ad0ecb853",
        ladder: "APEX LEGENDS LADDER",
        leaderboards: {
            tracking: ["NICKNAME", "KILLS"],
            trackingFilters: ["KILLS"]
        },
        info: {
            title:"Apex Legends. Статистика игроков и аккаунтов",
            h1:"Apex Legends",
            h2:"Apex Legends статистика",
            text1: "Покорите вершину славы в Apex Legends, бесплатном шутере в жанре королевской битвы, где легендарные персонажи с мощными умениями сражаются за звание лучших на всем Фронтире. Освойте стиль боя каждой легенды, число которых постоянно растет, а также тонкости тактической игры в отряде и смелые новшества, поднявшие планку жанра на новый уровень. Apex Legends статистика игрока доступна в PLINK с момента регистрации.",
            text2: "Находи товарищей по команде в апекс легендс, и делай поиск напарников в apex legends с помощью приложения PLINK. Добро пожаловать в королевскую битву следующего поколения. Скачай приложение прямо сейчас и будь одним из первых!",
        },
        brawlers: false,
    },
    "bs": {
        name: "Brawl Stars",
        poster: "posterBs",
        background: "bgBs",
        link: "bs",
        gameSlug: "8d12e0ce-3dc4-4eb3-80cd-3bdf4c311e4b",
        ladder: "BS LADDER",
        leaderboards: {
            tracking: ["BRAWL TAG", "TROPHY"],
            trackingFilters: ["TROPHY"]
        },
        info: {
            title:"Brawl Stars. Players and account stats",
            h1:"Brawl Stars, players stats",
            h2:"Brawl Stars в PLINK. Играем, общаемся, смотрим статистику игроков",
            text1: "Brawl Stars - боевая игра в режиме реального времени от разработчиков Clash of Clans и Clash Royale, в которой две команды по три участника должны сражаться в обстановке с препятствиями и разными интерактивными элементами. Brawl Stars статистика игрока доступна в PLINK с момента регистрации. Ваша цель: хорошенько проучить другую команду.",
            text2: "Управлять своим персонажем в Brawl Stars довольно легко, на самом деле, даже одного пальца для этого достаточно при вертикальном положении устройства. Вам только нужно нажимать и водить пальцем по экрану для перемещения персонажа и совершения атак. Таким же образом вы можете проявить свои специальные способности, которые просто необходимы, если вы хотите иметь шанс на победу. Браво Старс!",
        },
        brawlers: {
            linkText: "Brawlers",
            link: "brawlers",
            characters: [
                {
                    name:"Rosa",
                    title:"Роза brawl stars. Характеристики персонажа Rosa бравл старс",
                    description:"Роза - это боксёрша-ботаник, чтобы выжить в джунглях, ей пришлось лупить растения изо всех сил.",
                    link:"rosa",
                    h1:"Роза",
                    h2:false,
                    text1:"Роза -  это боксёрша-ботаник, чтобы выжить в джунглях, ей пришлось лупить растения изо всех сил. Базовая атака бойца — улучшенная версия кулаков Эль Примо, поэтому от нее бывает весьма сложно увернуться, но она должна подойти близко к своим целям, чтобы нанести хороший урон.",
                    text2:"Супер сила Розы - это сила природы; в течение 3 секунд Роза получает второй слой крепкой лозы, уменьшая весь получаемый урон на 70%. Когда Роза находится в кустах, она восстанавливает 200 единиц здоровья каждую секунду.",
                    img1:"brawlRosa1",
                    img2:"brawlRosa2",
                    preview:"rosaPre",
                },
                {
                    name:"Carl",
                    title:"Shelly brawl stars. Характеристики персонажа Шелли в бравл старс",
                    description:"Карл это Сверхредкий Боец, который имеет умеренное здоровье и высокий урон. ",
                    link:"carl",
                    h1:"Карл",
                    h2:false,
                    text1:"Карл это Сверхредкий Боец, который имеет умеренное здоровье и высокий урон. Он бросает свою кирку, как бумеранг; после релиза он получил серьезный баф, так как полет его кирки стал быстрее на 25%. Суператака Карла - сумасшедшее вращение вагонетки, которая сбивает любого вокруг него.",
                    text2:" Карл отлично зарекомендовал себя в большинстве режимов игры, и лишь в «Ограблении» он уступает конкурентам, так как не может нанести быстрый урон по сейфу. В целом трюки с отскакивающей от стен киркой и мощная ульта делают Карла крайне универсальным бойцом.",
                    img1:"brawlCarl1",
                    img2:"carl",
                    preview:"carlPre",
                },
                {
                    name:"Brock",
                    title:"Брок brawl stars. Как играть за брока. Характеристики Brock brawl stars",
                    description:"Брок удерживает звание лучшего дальника в Brawl Stars. Он может нанести серьезный урон, если его атаки нацелены аккуратно. ",
                    link:"brock",
                    h1:"Брок",
                    h2:false,
                    text1:"Брок удерживает звание лучшего дальника в Brawl Stars. Он может нанести серьезный урон, если его атаки нацелены аккуратно. Его 9 дальнобойных ракет наносят большой урон при ударе, но у него долгое время перезарядки. Эти ракеты могут разрушать препятствия.",
                    text2:"Диапазон этой атаки аналогичен основной атаке Брока. Брок прекрасно справляется с ролью поддержки в «Награде за поимку», «Ограблении» и парном «Столкновении».",
                    img1:"brawlBrock1",
                    img2:"brock",
                    preview:"brockPre",
                },
                {
                    name:"Pam",
                    title:"Пэм brawl stars. Характеристики персонажа Pam из бравл старс",
                    description:"Пэм - эпический боец, с быстрой базовой атакой; она выпускает кучу пуль на приличную дистанцию с небольшим радиусом.",
                    link:"pam",
                    h1:"Пэм",
                    h2:false,
                    text1:`Пэм - эпический боец, с быстрой базовой атакой; она выпускает кучу пуль на приличную дистанцию с небольшим радиусом. У Пэм хороший запас здоровья и прекрасная лечащая турель, у которой довольно большой радиус, что позволяет восстанавливать здоровья ей и её товарищам по команде, даже если они стреляют. С Пэм лучше всего атаковать с ближнего расстояния, чтобы противник получил больше "снарядов" на себя.`,
                    text2:"Она намного более эффективна против врагов с изначально небольшим уровнем здоровья. Благодаря такому набору способностей Пэм будет идеальным дополнением для любой команды, хотя и в соло режиме бойца крайне сложно остановить.",
                    img1:"brawlPam1",
                    img2:"pam",
                    preview:"pamPre",
                },
            ]
        }
    },
}