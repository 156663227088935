import React, { Component } from 'react'
import './Footer.css'
// import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import languages from '../etc/languages'

class Footer extends Component {
  render() {
    const { locale } = this.props
    return (
      <div>
        <div className='footer flex footerF1'>
          <div className='flex flex1 jcCenter aiCenter'>
            <div className='footerF1 mleft72'>{languages[locale].LANDING_TITLE_67}</div>
            {/* <a href="https://plink.tech/static/pages/pdfs/PP.pdf" target="_blank" style={{textAlign:"center", color:"#ff55aa"}} className="ffont6 flex aiCenter jcCenter clickable mright72">Data Privacy</a> */}
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

const mapStateToProps = state => {
  return {
    locale: state.locale
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Footer)
