import React, { Component } from 'react'
import '../App.css'
import './Header.css'
import { connect } from 'react-redux'
import Cookies from 'universal-cookie'
// import { NavLink, Redirect } from 'react-router-dom'
import languages from '../etc/languages'
import { changeLocale } from '../actions'
// import { dispatch } from 'rxjs/internal/observable/range'

const cookies = new Cookies()

class Header extends Component {
  state = {
    auth: false,
    token: false
  }

  // constructor(props) {
  //   super(props)
  // }

  // accessToken

  render() {
    const { locale, changeLocale, handleBurger } = this.props
    return (
      <div className='header flex aiCenter jcCenter'>
        <div
          style={{ height: '100%' }}
          className='flex aiCenter jcCenter relative LandingBlockWrapSize mLandingBlockWrapSize LandingFWide1280'
        >
          <div style={{ left: '0px', top: '20px' }} className='HeaderF1_icon absolute'>
            {/* {languages[locale].HEADER_TITLE} */}
          </div>
          <div
            onClick={handleBurger}
            style={{ right: '0px', top: '23px', fontSize: '16px' }}
            className='HeaderF1 absolute flex aiCenter clickable HeaderMflex burgerButton'
          >
            <div className='LandingBurgIcon'></div>
          </div>
          {/*
          <div
            onClick={() => changeLocale(locale === 'ru' ? 'en' : 'ru')}
            style={{ right: '0px', top: '20px', fontSize: '16px' }}
            className='HeaderF1 absolute flex aiCenter clickable HeaderMhid'
          >
            <div className={`${locale !== 'ru' ? 'rulang' : 'enlang'} lang`}></div>
            <div className='mleft12'>{languages[locale].CHANGE_LOCALE}</div>
          </div>
          */}
          <a href='#advantages' className='HeaderF2 HeaderMhid'>
            {languages[locale].MENU_ADVANTAGES}
          </a>
          <a href='#overview' className='HeaderF2 HeaderMhid mleft48'>
            {languages[locale].MENU_OVERVIEW}
          </a>
          <a href='#integration' className='HeaderF2 HeaderMhid mleft48'>
            {languages[locale].MENU_INTEGRATION}
          </a>
          <a href='#dashboard' className='HeaderF2 HeaderMhid mleft48'>
            {languages[locale].MENU_DASHBOARD}  
          </a>
          <a href='#competitors' className='HeaderF2 HeaderMhid mleft48'>
            {languages[locale].MENU_COMPETITORS}
          </a>
          <a href='#pricing' className='HeaderF2 HeaderMhid mleft48'>
            {languages[locale].MENU_PRICING}
          </a>
          <div className='header__SocialsLinks'>
            <div className="socialLink"><a target='_blank' href='https://www.linkedin.com/company/sonus-io/'/></div>
            <div className="socialLink"><a target='_blank' href='https://twitter.com/Sonus__'/></div>
          </div>
        </div>
        {/* <div className="flex jcBetween">
                </div>
                <div className="flex flex1 jcEnd">
                    
                </div> */}
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeLocale: id => {
      dispatch(changeLocale(id))
    }
  }
}

const mapStateToProps = state => {
  return {
    // user: state.user.user,
    locale: state.locale
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)
